import React, { Component } from 'react'

import './Punnett-Inputs.css'


class ColumnDisplay extends Component<{ index: number, content: string[], removeColumnAtIndex: (inxex: number) => void, updateContent: (i: number, content: string[]) => void }, {currentTextboxText:string}> {
    constructor(public props: any) {
        super(props);

        this.state = {
            currentTextboxText: ""
        };

    }

    renderList() {
        return this.props.content.map((input: any, index: number) => {
            return (
                //Todo this should be a text field, that should be able to modify the state of the whole thing.
                <li className="list-item" key={index}> {input} </li>
            )
        })
    }

    handleUserSubmitEvent = (event: any) => {
        console.log(event);
        event.preventDefault();
        this.props.updateContent(this.props.index, [...this.props.content, this.state.currentTextboxText]);
        this.setState({currentTextboxText: ""});
    }

    handleTextChanged= (event: any) => {
        this.setState({currentTextboxText: event.target.value});
    }

    render() {
        //Render the contents of our coloumnmnm
        return (
            <ol className="coloumn-display">

                <h5 className="list-header">
                    Inputs {this.props.index + 1}
                    <div className="button-container">
                        <button className="button" onClick={() => this.props.removeColumnAtIndex(this.props.index)}>
                            ✖
                        </button>
                    </div>
                </h5>
                {this.renderList()}
                <form className="add-list-item" onSubmit={this.handleUserSubmitEvent}>
                    <input className="input-box" type="text" value={this.state.currentTextboxText} onChange={this.handleTextChanged}/>
                    <input type="submit" value="Submit" />
                </form>
            </ol>
        )
    }
}

/** todo:
 * Create component that allows you to define an array of options
 * Create a divider component
 * Some way to add multiple option arrays ( (op op op) (divider) (op op op) ...)
 */

class PunnettInputs extends Component<{ setTable: any }, { inputColumns: string[][] }> {

    private currentId: number = 4;

    private seperator = ",";

    constructor(public props: any) {
        super(props);

        this.state = {
            inputColumns: [["A", "B", "C"], ["X", "Y", "Z"]]
        };
    }

    removeColumnAtIndex = (indexToRemove: number) => {
        this.setState({
            inputColumns: this.state.inputColumns.filter((value: string[], index: number) => index !== indexToRemove)
        });
    }

    updateColumnAtIndex = (index: number, newContent: string[]) => {
        //lmao this is horrible
        this.setState({
            inputColumns: this.state.inputColumns.map((value: string[], i: number) => {
                if (i === index) return newContent;
                else return value;
            })
        });
    }

    renderColoumns() {
        return this.state.inputColumns.map((col: any, index: number) => {
            return (
                <ColumnDisplay key={index} index={index} content={col} removeColumnAtIndex={this.removeColumnAtIndex} updateContent={this.updateColumnAtIndex} />
            )
        });
    }

    recursiveCompileStrings(strings: string[], currentString: string, index: number) {
        if(index < this.state.inputColumns.length) {
            for(let i = 0; i < this.state.inputColumns[index].length; i++) {
                if(index !== 0){
                    this.recursiveCompileStrings(strings, currentString + this.seperator + this.state.inputColumns[index][i], index + 1);
                }
                else {
                    this.recursiveCompileStrings(strings, currentString + this.state.inputColumns[index][i], index + 1);
                }                
            }
        }
        else {
            strings.push(currentString);
        }
    }

    updateTable() {

        let createdStrings: string[] = [];
        
        this.recursiveCompileStrings(createdStrings, "", 0);

        this.props.setTable((currentTable: any) => {
            return {
                data: createdStrings,
            };
        })
    }

    addColoumn() {
        if (this.state.inputColumns.length === 0 || this.state.inputColumns[this.state.inputColumns.length - 1].length > 0) {
            this.setState({
                inputColumns: [...this.state.inputColumns, []]
            });
        }
    }

    render() {

        return (
            <React.Fragment>
                <div>
                    
                    {/* <div>
                        <h6 className="seperator-display">
                            Seperator:
                        </h6>
                    </div> */}
                    <div className="input-display">
                        {this.renderColoumns()}

                        <div className="button-container">
                            <button className="button" onClick={() => this.addColoumn()}>
                                +
                            </button>
                        </div>
                    </div>
                    <button onClick={() => this.updateTable()}>
                        Update Table
                    </button>
                </div>
            </React.Fragment>
        )
    }
}

export default PunnettInputs;//exporting a component make it reusable and this is the beauty of react