import React, { Component } from 'react'

import './Punnett-Table.css'

class PunnettTable extends Component<{table: any, setTableDisplayRef: any}> {

    private tableRef: any;

    constructor(public props: any) {
        super(props); //since we are extending class Table so we have to use super in order to override Component class constructor  
        this.tableRef = React.createRef(); 

        this.props.setTableDisplayRef((currentRef: any) => {
            return this.tableRef;
        });
    }

    /** todo: This should render the data given by the input component across the top and side columns to create a punnett square thing.
     * 
     * @returns the table to render
     */
    renderTableData() {
        return this.props.table.data.forEach((row: String, index: number) => {
            return (
                <tr>
                    <td>{index}</td>
                    <td>{row}</td>
                </tr>
            )
        });
    }

    renderTableHeader() {
        if(this.props.table.data) {
            return this.props.table.data.map((data: string, index: number) => {
                return( <td>{data}</td> )
            });
        }
        else {
            console.log(this.props);
        }
    }

    renderTableRows() {
        if(this.props.table.data) {
            return this.props.table.data.map((data: string, index: number) => {
                return( 
                    <tr>
                        <td>{data}</td>  
                        {this.props.table.data.map(() => <td></td>)}
                    </tr>
                )
            });
        }
        else {
            console.log(this.props);
        }
    }

    render() { //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
        return (
            <div className="table-container">
                <h3 id='title'>Generated Table:</h3>
                <table id='students'  ref={this.tableRef}>
                    <tbody>
                        <tr>
                            <td>╳</td>
                            {this.renderTableHeader()}
                        </tr>
                        {this.renderTableRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PunnettTable;//exporting a component make it reusable and this is the beauty of react