import React from 'react';
import PunnettTable from './components/Punnett-Table'
import PunnettInputs from './components/Punnett-Inputs'
import TableExporter from './components/export-table';
import './App.css';

function App() {
  const [table, setTable] = React.useState({ //state is by default an object
    data: []
  });

  const [tableDisplayRef, setTableDisplayRef] = React.useState({ current: null });

  document.title = "Punnett-thing";

  return (
    <div className="App">
      <header className="App-header">
        <PunnettInputs setTable={setTable} />
        <PunnettTable table={table} setTableDisplayRef={setTableDisplayRef}/>
        <TableExporter ComponentRef={tableDisplayRef}/>
      </header>
    </div>
  );
}

export default App;
