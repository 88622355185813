import { exportComponentAsPNG } from 'react-component-export-image';
import React from 'react';

export default class TableExporter extends React.Component<{ComponentRef: React.RefObject<any>}> {

    constructor(public props: any) {
        super(props);
    }

    render() {
        return (
            <span>
                <button onClick={() => exportComponentAsPNG(this.props.ComponentRef, {fileName: "PunnettTableThing"})}>
                    Export As PNG
                </button>
            </span>
        );
    }
}